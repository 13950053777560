import { Box } from '@chakra-ui/react'
import Authentication from '@/modules/Authentication'
import Meta from '@/components/Meta'
import { APP_NAME } from '@/lib/constants/appConstants'

const Login = () => {
  return (
    <>
      <Meta
        title={`Log in to your ${APP_NAME} Account - Online Exam Portal`}
        description={`Log in to your ${APP_NAME} account, just enter your information to find your account and experience the most used online examination platform.`}
      />
      <Box as="section">
        <Authentication type="login" />
      </Box>
    </>
  )
}

export default Login
